import React from "react"
import "./style.scss"

import FacebookIcon from "./FacebookIcon"
import InstagramIcon from "./InstagramIcon"
import LinkedInIcon from "./LinkedInIcon"

const SocialIconsBar = () => {
  return (
    <div className="social-icons">
      <a
        href="https://www.linkedin.com/in/daniela-gerstmann-148933114/"
        target="_blank"
        rel="noreferrer"
        aria-label="Daniela's LinkedIn profile"
      >
        <LinkedInIcon />
      </a>
      <a
        href="https://www.facebook.com/danielagerstmann"
        target="_blank"
        rel="noreferrer"
        aria-label="Daniela's Facebook profile"
      >
        <FacebookIcon />
      </a>
      <a
        href="https://www.instagram.com/daniela_on_the_road/"
        target="_blank"
        rel="noreferrer"
        aria-label="Daniela's Instagram profile"
      >
        <InstagramIcon />
      </a>
    </div>
  )
}

export default SocialIconsBar
