import React from "react"

import "./style.scss"

const NavBackdrop = props => {
  return (
    <div
      className="backdrop"
      onClick={props.onClick}
      onKeyDown={props.handleKeyDown}
      aria-label="Close navigation"
      role="button"
      tabIndex="0"
    />
  )
}

export default NavBackdrop
