import React, { useState } from "react"
import { Link } from "gatsby"
import Navbar from "./Navbar/index"
import BurgerButton from "./BurgerButton/index"
import NavBackdrop from "./NavBackdrop/index"
import "./style.scss"

const Header = props => {
  const [showSideNav, setShowSideNav] = useState(false)

  const onBurgerClick = () => {
    setShowSideNav(!showSideNav)
  }

  const onCloseClick = () => {
    setShowSideNav(false)
  }

  const handleKeyDown = ev => {
    if (ev.keyCode === 13) {
      setShowSideNav(false)
    }
  }

  return (
    <header className="header sticky-inner">
      <nav>
        <Link to="/" className="logo-div" style={{ opacity: props.opacity }}>
          <p className="logo-name">Daniela Gerstmann</p>
          <p className="logo-tagline">creative producing & arts marketing</p>
        </Link>

        <div className="horizontalNav">
          <Navbar />
        </div>
        <div className="burger">
          <BurgerButton onClick={onBurgerClick} />
        </div>

        <div className={`sideNav ${showSideNav && "show"}`}>
          <Navbar
            sideNav={true}
            showSideNav={showSideNav}
            onClick={onCloseClick}
          />
        </div>
        {showSideNav && (
          <NavBackdrop onClick={onCloseClick} onKeyDown={handleKeyDown} />
        )}
      </nav>
    </header>
  )
}

export default Header
