import React from "react"
import { Link } from "gatsby"
import CookieConsent from "react-cookie-consent"

import "./style.scss"

const CookieConsentBanner = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Okay"
      declineButtonText="No thanks"
      style={{
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "3px",
        boxShadow: "-1px 0px 12px 3px rgba(43,43,43,0.13)",
        fontSize: "13px",
        background: "#fafafa", // offwhite
        fontFamily: `"Header", sans-serif`,
        color: "#2b2b2b", // black
      }}
      buttonStyle={{
        fontFamily: `"Header", sans-serif`,
        color: "#fafafa", // offwhite
        fontSize: "13px",
        border: "1px solid #fafafa", // offwhite
        borderRadius: "3px",
        background: "#930064", // duskypink
        padding: "5px 15px",
        outline: "none",
      }}
      // onAccept={() => {
      //   Cookies.set("gatsby-gdpr-google-analytics", "true")
      // }}
    >
      We use cookies to provide you with the best possible user experience.
      Click{" "}
      <Link to="/privacy" style={{ textDecoration: `underline` }}>
        here
      </Link>{" "}
      for more information.
    </CookieConsent>
  )
}

export default CookieConsentBanner
