import React from "react"
import { Link } from "gatsby"
import SocialIconsBar from "../SocialIconsBar/index"

import "./style.scss"

const Footer = () => {
  return (
    <footer>
      <div className="footer-div">
        <div className="footer-year-div">
          <p>© {new Date().getFullYear()} Daniela Gerstmann</p>
          <div className="social-icons-div__medium">
            <SocialIconsBar />
          </div>
        </div>

        <div className="social-icons-div">
          <SocialIconsBar />
        </div>

        <div className="footer-link-div">
          <p>
            <Link to="/privacy" className="footer-link-div-privacy">
              Privacy Policy
            </Link>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
