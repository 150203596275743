import React from "react"
import "./style.scss"

const BurgerButton = props => {
  return (
    <button className="burger-button" aria-label="Menu" onClick={props.onClick}>
      <div className="burger-line" />
      <div className="burger-line" />
      <div className="burger-line" />
    </button>
  )
}

export default BurgerButton
