import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import "./style.scss"

const Navbar = ({ sideNav, showSideNav, onClick }) => {
  const data = useStaticQuery(
    graphql`
      query {
        sanitySectionAbout {
          name
        }
        sanitySectionServices {
          name
        }
        sanitySectionClients {
          name
        }
        sanitySectionPortfolio {
          name
        }
        sanitySectionCv {
          name
        }
        sanitySectionTestimonials {
          name
        }
        sanitySectionContact {
          name
        }
      }
    `
  )

  return (
    <ul
      className={`navList ${
        sideNav && (showSideNav ? "sideNav show" : "sideNav")
      }`}
    >
      <li>
        <Link to={`/#${data.sanitySectionAbout.name}`} onClick={onClick}>
          {data.sanitySectionAbout.name}
        </Link>
      </li>
      <li>
        <Link to={`/#${data.sanitySectionServices.name}`} onClick={onClick}>
          {data.sanitySectionServices.name}
        </Link>
      </li>
      <li>
        <Link to={`/#${data.sanitySectionClients.name}`} onClick={onClick}>
          {data.sanitySectionClients.name}
        </Link>
      </li>
      <li>
        <Link to={`/#${data.sanitySectionPortfolio.name}`} onClick={onClick}>
          {data.sanitySectionPortfolio.name}
        </Link>
      </li>
      <li>
        <Link to={`/#${data.sanitySectionCv.name}`} onClick={onClick}>
          {data.sanitySectionCv.name}
        </Link>
      </li>
      <li>
        <Link to={`/#${data.sanitySectionTestimonials.name}`} onClick={onClick}>
          {data.sanitySectionTestimonials.name}
        </Link>
      </li>
      <li>
        <Link to={`/#${data.sanitySectionContact.name}`} onClick={onClick}>
          {data.sanitySectionContact.name}
        </Link>
      </li>
    </ul>
  )
}

export default Navbar
